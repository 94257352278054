/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import _ from "lodash"
import "intersection-observer"
import scrollama from "scrollama"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`{
  wechatQrcode: file(relativePath: {eq: "wechat-qrcode.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 120, height: 120, layout: FIXED)
    }
  }
}
`)

  // 颜色切换
  const [colorBg, setColorBg] = useState("#ffffff")
  const [colorText, setColorText] = useState("#000000")

  useEffect(() => {
    let sections = document.getElementsByClassName(
      "is-in-viewport js-viewport-animation"
    )

    const ColorChange = () => {
      if (sections.length > 0) {
        setColorBg(sections[0].getAttribute("data-bg-color"))
        setColorText(sections[0].getAttribute("data-color"))
      }
    }

    if (sections) {
      window.addEventListener("scroll", ColorChange, false)
    }
    return () => {
      if (sections) {
        window.removeEventListener("scroll", ColorChange)
      }
    }
  }, [])

  // 导航交互
  useEffect(() => {
    let scrollAction = undefined
    const menuEffect = () => {
      let scrollTop = window.scrollY

      if (typeof scrollAction == "undefined") {
        scrollAction = scrollTop
      }
      let diffY = scrollAction - scrollTop

      if (diffY < 0) {
        document.querySelector(".header").classList.add("is-scroll-down")
      } else if (diffY >= 0) {
        document.querySelector(".header").classList.remove("is-scroll-down")
      }

      if (scrollTop <= 0) {
        document.querySelector(".header").classList.remove("is-scroll-down")
      }
      scrollAction = scrollTop

      if (window.scrollY < document.querySelector(".banner").innerHeight / 2) {
        document.querySelector(".header").classList.add("is-first-slide")
      } else {
        document.querySelector(".header").classList.remove("is-first-slide")
      }
    }

    if (document.querySelector(".header")) {
      window.addEventListener("scroll", menuEffect, true)
    }

    return () => {
      if (document.querySelector(".header")) {
        window.removeEventListener("scroll", menuEffect)
      }
    }
  }, [])

  //Wow.js Init
  useEffect(() => {
    if (typeof window !== undefined) {
      window.wow.init()
    }
  }, [])

  //Viewport animation
  useEffect(() => {
    const scroller = scrollama()
    scroller
      .setup({
        step: ".js-viewport-animation",
      })
      .onStepEnter((response) => {
        response.element.classList.add(
          "is-in-viewport",
          "is-first-time-in-viewport"
        )
      })
      .onStepExit((response) => {
        response.element.classList.remove("is-in-viewport")
      })
    let jsViewportAnimation = document.getElementsByClassName(
      "js-viewport-animation"
    )
    if (jsViewportAnimation) {
      window.addEventListener("resize", scroller.resize)
    }
    return () => {
      if (jsViewportAnimation) {
        window.removeEventListener("resize", scroller.resize)
      }
    }
  }, [])

  // Text Animation 1
  const nodeContainMarkup = (t) => {
    for (let e = t.childNodes, i = e.length; i--; )
      if (1 === e[i].nodeType) return !0
    return !1
  }

  const splitStrPerLines = (t) => {
    let e = t.textContent.split(" "),
      i = 0,
      n = 0
    t.innerHTML = ""
    for (let r = 0; r < e.length; r++) {
      let o = e[r]
      if ("" !== o) {
        t.insertAdjacentHTML(
          "beforeend",
          '<span class="js-word">' + o + "</span> "
        )
        let s = t.lastChild.previousSibling
        s.offsetTop > i && ((i = s.offsetTop), n++),
          s.classList.add("is-line-" + n)
      }
    }
  }

  const setTextAnimation = (ele) => {
    for (let t = 0; t < ele.length; t++) {
      let e = ele[t],
        i = nodeContainMarkup(e),
        n =
          e.classList.contains("has-lines-animation") &&
          !e.classList.contains("is-animation-completed")
      !i || n
        ? (splitStrPerLines(e),
          e.classList.add("is-animation-ready", "has-lines-animation"))
        : e.classList.add("text-sweet-in")
    }
  }

  useEffect(() => {
    let text = document.querySelectorAll(".js-text-animation")
    if (text.length > 0) {
      setTextAnimation(text)
    }
  }, [])

  //Text animation 2
  useEffect(() => {
    const scroller = scrollama()
    scroller
      .setup({
        step: ".js-text-animation",
        offset: 0.75,
      })
      .onStepEnter((response) => {
        response.element.classList.add("is-in-viewport")
      })
      .onStepExit((response) => {
        response.element.classList.add("is-animation-completed")
      })
    let jsTextAnimation = document.getElementsByClassName("js-text-animation")
    if (jsTextAnimation) {
      window.addEventListener("resize", scroller.resize)
    }
    return () => {
      if (jsTextAnimation) {
        window.removeEventListener("resize", scroller.resize)
      }
    }
  }, [])

  useEffect(() => {
    let langButton = document.getElementById("langButton")

    const handleLanguageChange = function () {
      const pathname = window.location.pathname
      const isEn = _.startsWith(pathname, "/en/")

      if (isEn) {
        const path = _.replace(pathname, "/en/", "/")
        navigate(path)
      } else {
        const path = "/en" + pathname
        navigate(path)
      }
    }

    langButton.addEventListener("click", handleLanguageChange, true)
    return () => {
      langButton.removeEventListener("click", handleLanguageChange)
    }
  }, [])

  return <>
    <main
      className="cnc"
      style={{ backgroundColor: colorBg, color: colorText }}
    >
      {children}
    </main>
    <footer
      className="footer enc js-viewport-animation"
      data-bg-color="#323232"
      data-color="#fff"
    >
      <div className="wp1180">
        <div className="ft-link">
          <Link to="/privacy/" className="link-btn">
            隐私政策
          </Link>
        </div>
        <div className="ft-share">
          <a
            className="s1"
            href="https://www.linkedin.com/company/13404448/"
            target="__blank"
          ></a>
          <a
            className="s2"
            href="https://www.instagram.com/sunnwello%EF%AC%83cial/"
            target="__blank"
          ></a>
          <a className="s3">
            <div className="img">
              <GatsbyImage image={data.wechatQrcode?.childImageSharp?.gatsbyImageData} alt="" />
            </div>
          </a>
        </div>
        <div className="copyright">
          &copy; 2017 - {new Date().getFullYear()}, SUN &amp; WELL DESIGN AND
          CONSTRUCTION CO,LTD.&nbsp;SITE BY{" "}
          <a href="https://www.fim.com.cn" target="__blank">
            FIM
          </a>
          .
        </div>
      </div>
    </footer>
  </>;
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
