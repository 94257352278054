import { Link } from "gatsby"
import PropTypes from "prop-types"
import _ from "lodash"
import React, { useState, useEffect } from "react"

const homeSvg = (
  <svg
    className="pic"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 497.93 154.7"
  >
    <g>
      <g>
        <path d="M90.93,110.44c0-28.92-86.11-10.3-86.11-65.3C4.82,14.46,33.3,0,60.48,0c30.67,0,58.28,13.59,59.38,47.55H88.09C86.33,32,77.35,27,60.91,27c-9.2,0-22.13,4.38-22.13,15.55,0,26.52,86.12,7.45,86.12,64.2,0,35.06-31.34,48-61.79,48C30.24,154.7.22,138.48,0,102.11H33.3C34.18,119.86,48.2,128,64.42,128,74.72,128,90.93,123.81,90.93,110.44Z" />
        <path d="M240.15,151.41,227,135c-11,10.52-24.55,18.41-40.1,18.41-26.52,0-48.43-18.63-48.43-45.8,0-16.87,8.55-28.92,21.91-38.35,2.63-2,5.26-3.5,7.89-5.47-7-7.89-12.27-16.88-12.27-27.61C156,14.25,172,0,193.48,0c20.38,0,37.25,13.37,37.25,34.62,0,15.56-10.74,25.2-22.13,33.75L229,93.13q7.89-8.88,15.12-18.41l19.06,19.5a211.74,211.74,0,0,1-16.65,20.38l29.8,36.81ZM185.59,84.8c-6.79,4.6-19.28,13.15-19.28,22.35,0,10.08,9.2,19.5,19.5,19.5,8.76,0,17.53-7.23,23.45-12.92Zm-2.63-50c0,6.36,5,10.74,8.55,15.12,4.82-3.28,12.27-7.89,12.27-14.68,0-6.57-4.6-9.86-10.52-9.86C188.22,25.42,183,28.93,183,34.84Z" />
        <path d="M430.36,151.47h-39L360.47,47,329.35,151.47h-39l-28.92-149h36.15l17.53,102.76L344.25,2.47h32.21l29.15,102.76L423.13,2.47h36.16Z" />
        <path d="M481,33.8a16.9,16.9,0,1,1,16.9-16.9A16.92,16.92,0,0,1,481,33.8Zm0-30.66A13.76,13.76,0,1,0,494.79,16.9,13.77,13.77,0,0,0,481,3.14Z" />
        <path d="M486.26,26.5l-5-8.34h-3.71V26.5H475V6.86h6.46c4.18,0,7.18,1.42,7.18,5.52,0,3-1.85,4.87-4.66,5.52l5.28,8.6Zm-8.7-10.35H481c3.22,0,5-1.23,5-3.77s-1.77-3.51-5-3.51h-3.46Z" />
      </g>
    </g>
  </svg>
)
const aboutSvg = (
  <svg
    className="pic"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 368.53 49.1"
  >
    <g>
      <g>
        <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
        <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
        <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
        <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
        <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
        <path
          className="sub"
          d="M191.44,47.83,186.09,34.9H159.91l-5.54,12.93h-1.53l19.55-45.1h1.79L193,47.83ZM173.22,3.62l-12.8,30.07h25.22Z"
        />
        <path
          className="sub"
          d="M229.21,35.79a11.66,11.66,0,0,1-1.36,6,10.63,10.63,0,0,1-3.51,3.66,14.32,14.32,0,0,1-4.71,1.88,24.14,24.14,0,0,1-4.94.54H200.87V2.73h12.29a25.5,25.5,0,0,1,5.07.51A13.76,13.76,0,0,1,222.72,5a9.43,9.43,0,0,1,3.18,3.25,10,10,0,0,1,1.21,5.16,10.61,10.61,0,0,1-.76,4.2,9.4,9.4,0,0,1-2.07,3.06,11.11,11.11,0,0,1-3,2.07,16,16,0,0,1-3.38,1.18V24a15.08,15.08,0,0,1,3.69.92,12.7,12.7,0,0,1,3.67,2.13,11.4,11.4,0,0,1,2.8,3.57A11.16,11.16,0,0,1,229.21,35.79Zm-3.5-22.23A9,9,0,0,0,224.63,9,8.23,8.23,0,0,0,221.76,6a12.92,12.92,0,0,0-4.11-1.56A24,24,0,0,0,212.78,4H202.27V23.43H213.1a18.54,18.54,0,0,0,4.55-.57,13.22,13.22,0,0,0,4-1.75,9.44,9.44,0,0,0,2.9-3.06A8.67,8.67,0,0,0,225.71,13.56ZM227.81,36a10.29,10.29,0,0,0-1.24-5.22,10.8,10.8,0,0,0-3.15-3.51,13.35,13.35,0,0,0-4.21-1.94,17.56,17.56,0,0,0-4.46-.6H202.27V46.56h11.78a23.83,23.83,0,0,0,5.48-.61,12.6,12.6,0,0,0,4.37-1.88,9.07,9.07,0,0,0,2.86-3.28A10.26,10.26,0,0,0,227.81,36Z"
        />
        <path
          className="sub"
          d="M284.06,25.28a25.49,25.49,0,0,1-1.75,9.59,22.64,22.64,0,0,1-4.84,7.51,22,22,0,0,1-7.36,4.94,24.85,24.85,0,0,1-18.47,0,22,22,0,0,1-7.36-4.94,22.64,22.64,0,0,1-4.84-7.51,25.49,25.49,0,0,1-1.75-9.59,25.49,25.49,0,0,1,1.75-9.59,22.64,22.64,0,0,1,4.84-7.51,22,22,0,0,1,7.33-4.94,24.87,24.87,0,0,1,18.53,0,22,22,0,0,1,7.33,4.94,22.64,22.64,0,0,1,4.84,7.51A25.49,25.49,0,0,1,284.06,25.28Zm-1.4,0a24.23,24.23,0,0,0-1.59-8.82,21.72,21.72,0,0,0-4.49-7.17,21.1,21.1,0,0,0-6.91-4.81,23,23,0,0,0-17.59,0,21,21,0,0,0-6.91,4.81,21.72,21.72,0,0,0-4.49,7.17,24.23,24.23,0,0,0-1.59,8.82,24.65,24.65,0,0,0,1.56,8.82,21.57,21.57,0,0,0,4.43,7.17A20.57,20.57,0,0,0,252,46.08a23.58,23.58,0,0,0,17.83,0,20.57,20.57,0,0,0,6.88-4.81,21.57,21.57,0,0,0,4.43-7.17A24.65,24.65,0,0,0,282.66,25.28Z"
        />
        <path
          className="sub"
          d="M328.27,37.92a15.13,15.13,0,0,1-2.93,5.83A14,14,0,0,1,320,47.67a23.4,23.4,0,0,1-16.11,0,14,14,0,0,1-5.32-3.92,15.13,15.13,0,0,1-2.93-5.83,27.43,27.43,0,0,1-.9-7.16v-28h1.41V30.5a32.39,32.39,0,0,0,.54,5.7,14.86,14.86,0,0,0,2.23,5.61,13.24,13.24,0,0,0,4.77,4.3A16.92,16.92,0,0,0,312,47.83a17.13,17.13,0,0,0,8.13-1.66,13,13,0,0,0,4.81-4.23,15.13,15.13,0,0,0,2.29-5.61,31.51,31.51,0,0,0,.57-5.83V2.73h1.4v28A27.43,27.43,0,0,1,328.27,37.92Z"
        />
        <path className="sub" d="M353.18,4V47.83h-1.4V4H336.42V2.73h32.11V4Z" />
      </g>
    </g>
  </svg>
)
const servicesSvg = (
  <svg
    className="pic"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 405.66 49.1"
  >
    <g>
      <g>
        <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
        <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
        <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
        <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
        <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
        <path
          className="sub"
          d="M192.7,37a11.51,11.51,0,0,1-1.11,5.13,11.79,11.79,0,0,1-3,3.82,12.62,12.62,0,0,1-4.37,2.36,17.34,17.34,0,0,1-5.25.79,19.34,19.34,0,0,1-7.68-1.49,16.27,16.27,0,0,1-5.76-4l1.08-1a15.54,15.54,0,0,0,5.64,3.91,17.52,17.52,0,0,0,6.65,1.31,15,15,0,0,0,4.72-.73,12.78,12.78,0,0,0,4-2.1,9.79,9.79,0,0,0,2.7-3.38,10.33,10.33,0,0,0,1-4.62,8.31,8.31,0,0,0-1.08-4.39,10.73,10.73,0,0,0-2.8-3.06,17.31,17.31,0,0,0-3.92-2.14c-1.47-.57-2.94-1.09-4.43-1.56Q176.27,25,173.94,24a15.07,15.07,0,0,1-4-2.42,9.68,9.68,0,0,1-2.61-3.44,11.53,11.53,0,0,1-.93-4.87,10.92,10.92,0,0,1,1.12-5,10.79,10.79,0,0,1,3-3.7A13.94,13.94,0,0,1,175,2.25a17.57,17.57,0,0,1,5.32-.8,16.63,16.63,0,0,1,6.53,1.31,12.86,12.86,0,0,1,5.07,3.73l-1.09,1A12.07,12.07,0,0,0,186.24,4a14.54,14.54,0,0,0-6-1.24,15.82,15.82,0,0,0-4.49.64,12.54,12.54,0,0,0-4,1.94,10,10,0,0,0-2.86,3.28,9.56,9.56,0,0,0-1.08,4.65,9.39,9.39,0,0,0,.95,4.43,9.78,9.78,0,0,0,2.55,3.09,14.54,14.54,0,0,0,3.6,2.1q2,.83,4.11,1.53,2.87.95,5.35,1.94a19.27,19.27,0,0,1,4.36,2.39,10,10,0,0,1,2.93,3.41A10.37,10.37,0,0,1,192.7,37Z"
        />
        <path
          className="sub"
          d="M204,47.83V2.73h26.31V4H205.44V23.81h23.45v1.28H205.44V46.56h25.93v1.27Z"
        />
        <path
          className="sub"
          d="M267.24,47.83,253.6,25.09H243V47.83h-1.4V2.73h12.48a29.74,29.74,0,0,1,5,.44,12.78,12.78,0,0,1,4.56,1.69A9.76,9.76,0,0,1,267,8.27a10.94,10.94,0,0,1,1.27,5.61A10.42,10.42,0,0,1,267,19.16a10,10,0,0,1-3.12,3.41,12.82,12.82,0,0,1-4.23,1.88,19.11,19.11,0,0,1-4.56.57l13.7,22.81Zm-.39-33.95A9.53,9.53,0,0,0,265.71,9a8.6,8.6,0,0,0-3-3,12.89,12.89,0,0,0-4.33-1.57,28.6,28.6,0,0,0-5-.44H243V23.88h11q6.24,0,9.52-2.61A8.9,8.9,0,0,0,266.85,13.88Z"
        />
        <path
          className="sub"
          d="M292.21,47.83H290l-17-45.1h1.53l16.44,43.83h.13L307.88,2.73h1.53Z"
        />
        <path className="sub" d="M317.3,47.83V2.73h1.41v45.1Z" />
        <path
          className="sub"
          d="M361.93,47.42A24.55,24.55,0,0,1,353,49.1a23.54,23.54,0,0,1-9.3-1.78,21.65,21.65,0,0,1-12-12.49A26.52,26.52,0,0,1,330,25.28a26.57,26.57,0,0,1,1.69-9.56,22.05,22.05,0,0,1,4.75-7.54,21.78,21.78,0,0,1,7.29-4.94A23.68,23.68,0,0,1,353,1.45a24,24,0,0,1,8.19,1.44A17.38,17.38,0,0,1,368,7.25l-.89,1a15.41,15.41,0,0,0-3-2.45,19.28,19.28,0,0,0-3.56-1.75,20.29,20.29,0,0,0-3.79-1,22.85,22.85,0,0,0-3.7-.32,21.6,21.6,0,0,0-8.82,1.75,20.32,20.32,0,0,0-6.82,4.81A21.81,21.81,0,0,0,333,16.46a24.65,24.65,0,0,0-1.56,8.82A25.08,25.08,0,0,0,333,34.1a21.25,21.25,0,0,0,4.36,7.17,20.32,20.32,0,0,0,6.82,4.81A21.75,21.75,0,0,0,353,47.83a22.5,22.5,0,0,0,8.31-1.62,16.48,16.48,0,0,0,6.79-4.88l1,1A17.46,17.46,0,0,1,361.93,47.42Z"
        />
        <path
          className="sub"
          d="M378.33,47.83V2.73h26.31V4H379.73V23.81h23.44v1.28H379.73V46.56h25.93v1.27Z"
        />
      </g>
    </g>
  </svg>
)
const worksSvg = (
  <svg
    className="pic"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 348.58 49.1"
  >
    <g>
      <g>
        <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
        <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
        <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
        <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
        <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
        <path
          className="sub"
          d="M206.59,47.83h-2.16L191.37,4.07h-.13L178.43,47.83h-2.16L162.76,2.73h1.47l13,43.83h.19L190.22,2.73h2.17l13.05,43.83h.2l13-43.83h1.47Z"
        />
        <path
          className="sub"
          d="M272.08,25.28a25.69,25.69,0,0,1-1.75,9.59,22.66,22.66,0,0,1-4.85,7.51,22,22,0,0,1-7.35,4.94,24.87,24.87,0,0,1-18.48,0,22.07,22.07,0,0,1-7.36-4.94,22.64,22.64,0,0,1-4.84-7.51,25.49,25.49,0,0,1-1.75-9.59,25.49,25.49,0,0,1,1.75-9.59,22.64,22.64,0,0,1,4.84-7.51,22.15,22.15,0,0,1,7.33-4.94,24.9,24.9,0,0,1,18.54,0,22.11,22.11,0,0,1,7.32,4.94,22.66,22.66,0,0,1,4.85,7.51A25.69,25.69,0,0,1,272.08,25.28Zm-1.4,0a24.24,24.24,0,0,0-1.6-8.82,21.72,21.72,0,0,0-4.49-7.17,20.89,20.89,0,0,0-6.91-4.81,23,23,0,0,0-17.58,0,20.89,20.89,0,0,0-6.91,4.81,21.56,21.56,0,0,0-4.49,7.17,24,24,0,0,0-1.6,8.82,24.65,24.65,0,0,0,1.56,8.82,21.57,21.57,0,0,0,4.43,7.17A20.67,20.67,0,0,0,240,46.08a23.61,23.61,0,0,0,17.84,0,20.67,20.67,0,0,0,6.88-4.81,21.74,21.74,0,0,0,4.43-7.17A24.65,24.65,0,0,0,270.68,25.28Z"
        />
        <path
          className="sub"
          d="M309,47.83,295.39,25.09H284.82V47.83h-1.4V2.73H295.9a29.74,29.74,0,0,1,5,.44,12.78,12.78,0,0,1,4.56,1.69,9.76,9.76,0,0,1,3.28,3.41A10.94,10.94,0,0,1,310,13.88a10.42,10.42,0,0,1-1.21,5.28,10,10,0,0,1-3.12,3.41,12.82,12.82,0,0,1-4.23,1.88,19.11,19.11,0,0,1-4.56.57l13.7,22.81Zm-.38-33.95A9.53,9.53,0,0,0,307.5,9a8.6,8.6,0,0,0-3-3,12.89,12.89,0,0,0-4.33-1.57,28.6,28.6,0,0,0-5-.44H284.82V23.88h11q6.24,0,9.52-2.61A8.9,8.9,0,0,0,308.64,13.88Z"
        />
        <path
          className="sub"
          d="M322.72,23.43,345.4,2.73h2L324.5,23.43l24.08,24.4h-2L322.72,23.56h-.06V47.83h-1.4V2.73h1.4v20.7Z"
        />
      </g>
    </g>
  </svg>
)
const newsSvg = (
  <svg
    className="pic"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 340.68 49.1"
  >
    <g>
      <g>
        <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
        <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
        <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
        <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
        <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
        <path
          className="sub"
          d="M201.69,47.83,169.32,4.51h-.19V47.83h-1.4V2.73h2L202.13,46h.19V2.73h1.4v45.1Z"
        />
        <path
          className="sub"
          d="M217.23,47.83V2.73h26.31V4H218.63V23.81h23.44v1.28H218.63V46.56h25.93v1.27Z"
        />
        <path
          className="sub"
          d="M293.67,47.83h-2.16L278.45,4.07h-.13L265.51,47.83h-2.16L249.84,2.73h1.47l13,43.83h.2L277.3,2.73h2.17l13,43.83h.2l13-43.83h1.47Z"
        />
        <path
          className="sub"
          d="M340.68,37a11.51,11.51,0,0,1-1.11,5.13,11.65,11.65,0,0,1-3,3.82,12.62,12.62,0,0,1-4.37,2.36,17.34,17.34,0,0,1-5.25.79,19.34,19.34,0,0,1-7.68-1.49,16.27,16.27,0,0,1-5.76-4l1.08-1a15.54,15.54,0,0,0,5.64,3.91,17.52,17.52,0,0,0,6.65,1.31,15,15,0,0,0,4.72-.73,12.78,12.78,0,0,0,3.95-2.1,9.79,9.79,0,0,0,2.7-3.38,10.33,10.33,0,0,0,1-4.62,8.31,8.31,0,0,0-1.08-4.39,10.73,10.73,0,0,0-2.8-3.06,17.31,17.31,0,0,0-3.92-2.14c-1.47-.57-2.94-1.09-4.43-1.56Q324.25,25,321.92,24a15.07,15.07,0,0,1-4-2.42,9.68,9.68,0,0,1-2.61-3.44,11.53,11.53,0,0,1-.93-4.87,10.92,10.92,0,0,1,1.12-5,10.79,10.79,0,0,1,3-3.7,13.94,13.94,0,0,1,4.42-2.29,17.57,17.57,0,0,1,5.32-.8,16.63,16.63,0,0,1,6.53,1.31,12.86,12.86,0,0,1,5.07,3.73l-1.09,1A12.07,12.07,0,0,0,334.22,4a14.54,14.54,0,0,0-6-1.24,15.82,15.82,0,0,0-4.49.64,12.54,12.54,0,0,0-4,1.94,10,10,0,0,0-2.86,3.28,9.56,9.56,0,0,0-1.08,4.65,9.39,9.39,0,0,0,1,4.43,9.78,9.78,0,0,0,2.55,3.09,14.54,14.54,0,0,0,3.6,2.1q2,.83,4.11,1.53,2.86.95,5.35,1.94a19.27,19.27,0,0,1,4.36,2.39,10,10,0,0,1,2.93,3.41A10.37,10.37,0,0,1,340.68,37Z"
        />
      </g>
    </g>
  </svg>
)
const meetUsSvg = (
  <svg
    className="pic"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 462.23 49.1"
  >
    <g>
      <g>
        <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
        <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
        <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
        <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
        <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
        <path
          className="sub"
          d="M197.45,47.42a24.55,24.55,0,0,1-8.89,1.68,23.58,23.58,0,0,1-9.3-1.78,21.65,21.65,0,0,1-12-12.49,26.51,26.51,0,0,1-1.68-9.55,26.57,26.57,0,0,1,1.68-9.56A22.05,22.05,0,0,1,172,8.18a21.78,21.78,0,0,1,7.29-4.94,24.48,24.48,0,0,1,17.46-.35,17.38,17.38,0,0,1,6.78,4.36l-.89,1a15.64,15.64,0,0,0-3-2.45,19.69,19.69,0,0,0-3.57-1.75,20.29,20.29,0,0,0-3.79-1,22.85,22.85,0,0,0-3.7-.32,21.63,21.63,0,0,0-8.82,1.75,20.42,20.42,0,0,0-6.82,4.81,21.81,21.81,0,0,0-4.39,7.17A24.65,24.65,0,0,0,167,25.28a25.08,25.08,0,0,0,1.53,8.82,21.41,21.41,0,0,0,4.36,7.17,20.32,20.32,0,0,0,6.82,4.81,21.75,21.75,0,0,0,8.88,1.75,22.51,22.51,0,0,0,8.32-1.62,16.45,16.45,0,0,0,6.78-4.88l1,1A17.46,17.46,0,0,1,197.45,47.42Z"
        />
        <path
          className="sub"
          d="M257.3,25.28a25.69,25.69,0,0,1-1.75,9.59,22.66,22.66,0,0,1-4.85,7.51,22,22,0,0,1-7.35,4.94,24.87,24.87,0,0,1-18.48,0,21.93,21.93,0,0,1-7.35-4.94,22.66,22.66,0,0,1-4.85-7.51,25.69,25.69,0,0,1-1.75-9.59,25.69,25.69,0,0,1,1.75-9.59,22.66,22.66,0,0,1,4.85-7.51,22,22,0,0,1,7.32-4.94,24.9,24.9,0,0,1,18.54,0,22.11,22.11,0,0,1,7.32,4.94,22.66,22.66,0,0,1,4.85,7.51A25.69,25.69,0,0,1,257.3,25.28Zm-1.4,0a24.24,24.24,0,0,0-1.6-8.82,21.56,21.56,0,0,0-4.49-7.17,20.89,20.89,0,0,0-6.91-4.81,23,23,0,0,0-17.58,0,20.89,20.89,0,0,0-6.91,4.81,21.56,21.56,0,0,0-4.49,7.17,24,24,0,0,0-1.6,8.82,24.65,24.65,0,0,0,1.56,8.82,21.57,21.57,0,0,0,4.43,7.17,20.67,20.67,0,0,0,6.88,4.81,23.61,23.61,0,0,0,17.84,0,20.67,20.67,0,0,0,6.88-4.81,21.57,21.57,0,0,0,4.43-7.17A24.65,24.65,0,0,0,255.9,25.28Z"
        />
        <path
          className="sub"
          d="M302.59,47.83,270.23,4.51H270V47.83h-1.4V2.73h2L303,46h.19V2.73h1.4v45.1Z"
        />
        <path className="sub" d="M329.28,4V47.83h-1.4V4H312.53V2.73h32.1V4Z" />
        <path
          className="sub"
          d="M381.71,47.83,376.36,34.9H350.18l-5.55,12.93H343.1l19.56-45.1h1.79l18.79,45.1ZM363.49,3.62,350.68,33.69h25.23Z"
        />
        <path
          className="sub"
          d="M419.71,47.42a24.55,24.55,0,0,1-8.89,1.68,23.54,23.54,0,0,1-9.3-1.78,21.65,21.65,0,0,1-12-12.49,26.52,26.52,0,0,1-1.69-9.55,26.57,26.57,0,0,1,1.69-9.56,22.05,22.05,0,0,1,4.75-7.54,21.78,21.78,0,0,1,7.29-4.94,23.68,23.68,0,0,1,9.27-1.79A24,24,0,0,1,419,2.89a17.38,17.38,0,0,1,6.78,4.36l-.89,1a15.41,15.41,0,0,0-3-2.45,19.28,19.28,0,0,0-3.56-1.75,20.29,20.29,0,0,0-3.79-1,22.85,22.85,0,0,0-3.7-.32A21.6,21.6,0,0,0,402,4.48a20.32,20.32,0,0,0-6.82,4.81,21.81,21.81,0,0,0-4.39,7.17,24.65,24.65,0,0,0-1.56,8.82,25.3,25.3,0,0,0,1.52,8.82,21.43,21.43,0,0,0,4.37,7.17,20.38,20.38,0,0,0,6.81,4.81,21.84,21.84,0,0,0,8.89,1.75,22.5,22.5,0,0,0,8.31-1.62,16.55,16.55,0,0,0,6.79-4.88l1,1A17.46,17.46,0,0,1,419.71,47.42Z"
        />
        <path className="sub" d="M446.88,4V47.83h-1.4V4H430.12V2.73h32.11V4Z" />
      </g>
    </g>
  </svg>
)

const renderLogo = function (params) {
  switch (params) {
    case "home":
      return homeSvg

    case "about":
      return aboutSvg

    case "services":
      return servicesSvg

    case "works":
      return worksSvg

    case "news":
      return newsSvg

    case "meetus":
      return meetUsSvg

    default:
      return homeSvg
  }
}

const navs = {
  about: "关于我们",
  services: "服务范畴",
  works: "项目案例",
  meetus: "联系我们",
  news: "最新消息",
}

const renderNav = function (navs, active) {
  let html = []
  for (let [key, value] of Object.entries(navs)) {
    const activeKey = active === key ? "active" : ""
    let navHtml = (
      <li className={activeKey} key={key}>
        <Link to={`/${key}/`}>{_.toLower(value)}</Link>
      </li>
    )
    html.push(navHtml)
  }
  return html
}

const Header = ({ activePage, color, classLists }) => {
  const { bg, text } = color

  const classList = classLists ? classLists : ""

  const [colorBg, setColorBg] = useState(bg)
  const [colorText, setColorText] = useState(text)

  useEffect(() => {
    let sections = document.getElementsByClassName(
      "is-in-viewport js-viewport-animation"
    )

    const ColorChange = () => {
      if (sections.length > 0) {
        setColorBg(sections[0].getAttribute("data-bg-color"))
        setColorText(sections[0].getAttribute("data-color"))
      }

      if (window.pageYOffset <= window.innerHeight) {
        setColorBg(bg)
        setColorText(text)
      }
    }

    if (sections) {
      window.addEventListener("scroll", ColorChange, false)
    }
    return () => {
      if (sections) {
        window.removeEventListener("scroll", ColorChange)
      }
    }
  }, [])

  const [isMenuOpen, setisMenuOpen] = useState(false)

  useEffect(() => {
    let menuNotOpenDom = document.querySelector(".menuBtn:not(.open)")
    const menuOpen = () => {
      setisMenuOpen(true)
      menuNotOpenDom.classList.add("open")
      document.querySelector(".nav").classList.add("open")
      document.querySelector("body").classList.add("nav-open")
    }

    let menuOpenDom = document.querySelector(".menuBtn.open")
    const menuClose = () => {
      setisMenuOpen(false)
      menuOpenDom.classList.remove("open")
      document.querySelector(".nav").classList.remove("open")
      document.querySelector("body").classList.remove("nav-open")
    }

    if (!isMenuOpen) {
      menuNotOpenDom.addEventListener("click", menuOpen, false)
    } else {
      let navOpen = document.querySelector(".nav.open")
      navOpen.addEventListener("click", menuClose, true)
      menuOpenDom.addEventListener("click", menuClose, false)
    }

    return () => {
      if (!isMenuOpen) {
        menuNotOpenDom.removeEventListener("click", menuOpen)
      } else {
        menuOpenDom.removeEventListener("click", menuClose)
      }
    }
  }, [isMenuOpen])

  return (
    <header className={`header ${classList}`}>
      <div className="before" style={{ backgroundColor: colorBg }}></div>
      <div className="wp" style={{ color: colorText }}>
        <div className="logo">
          <Link to="/">{renderLogo(activePage)}</Link>
        </div>
        <div className="nav">
          <ul className="list cnlanglist">
            {renderNav(navs, activePage)}
            <li className="lang">
              <div id="langButton">EN</div>
            </li>
          </ul>
        </div>
        <div className="menuBtn"></div>
      </div>
    </header>
  )
}

Header.propTypes = {
  activePage: PropTypes.string,
}

Header.defaultProps = {
  activePage: `home`,
}

export default Header
